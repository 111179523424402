import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RegistrationService {

    constructor(private http: HttpClient) { }

    // register(roll_no:any) {
    //     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/register-mahe`, { enrollment_number: roll_no,user_type:"MAHE" }).pipe(map(user => {
    //         return user;
    //     }));
    // }

    // login(phone:any) {
    //     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/login`, { mobile: phone }).pipe(map(user => {
    //         return user;
    //     }));
    // }

    // resendotp(phone:any) {
    //     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/resend-otp`, { mobile_number: phone }).pipe(map(user => {
    //         return user;
    //     }));
    // }


    // nonmaheregister(name,email,phone,id_no) {
    //     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/register-non-mahe`, { full_name: name,user_type:"NONMAHE",email:email,mobile:phone,
    //     enrollment_number:id_no }).pipe(map(user => {
    //         return user;
    //     }));
    // }

    // verifyotp(no,otp:any) {
    //     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/verify-otp`, {otp: otp,mobile_number:no }).pipe(map(user => {
    //         if(user.status.code==200){
    //             localStorage.setItem(`${environment.appName}` + '_user', user.data.token);
    //             localStorage.setItem(`${environment.appName}` + '_name', user.data.user.full_name);
    //             localStorage.setItem(`${environment.appName}` + '_course_id', user.data.user.course_id);
    //             localStorage.setItem(`${environment.appName}` + '_degree_id', user.data.user.degree_id);
    //             localStorage.setItem(`${environment.appName}` + 'delegate_id', user.data.user.delegate_id);
    //             localStorage.setItem(`${environment.appName}` + 'enrollment_number', user.data.user.enrollment_number);
    //             localStorage.setItem(`${environment.appName}` + 'full_name', user.data.user.full_name);
    //             return user;
    //         }
    //     }));
    // }

}
