import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'mahe-register', loadChildren: () => import('./mahe-register/mahe-register.module').then(m => m.MaheRegisterModule) },
  { path: 'non-mahe-register', loadChildren: () => import('./non-mahe-register/non-mahe-register.module').then(m => m.NonMaheRegisterModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthSuperadminGuard]},
  { path: 'blacklist-dashboard', loadChildren: () => import('./blacklist-dashboard/blacklist-dashboard.module').then(m => m.BlacklistDashboardModule), canActivate: [AuthSuperadminGuard]},
  { path: 'events', loadChildren: () => import('./genral/genral.module').then(m => m.GenralModule), canActivate: [AuthSuperadminGuard]},
  { path: 'view-event', loadChildren: () => import('./view-event/view-event.module').then(m => m.ViewEventModule), canActivate: [AuthSuperadminGuard]},
  { path: 'payment', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule), canActivate: [AuthSuperadminGuard]},
  { path: 'success', loadChildren: () => import('./success/success.module').then(m => m.SuccessModule)},
  { path: 'myprofile', loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule), canActivate: [AuthSuperadminGuard]},
  { path: 'proshow', loadChildren: () => import('./proshow/proshow.module').then(m => m.ProshowModule), canActivate: [AuthSuperadminGuard]},
  { path: 'merchandise-details', loadChildren: () => import('./tshirt/tshirt.module').then(m => m.TshirtModule), canActivate: [AuthSuperadminGuard]},
  { path: 'events-schedules', loadChildren: () => import('./events-schedule/events-schedule.module').then(m => m.EventsScheduleModule), canActivate: [AuthSuperadminGuard]},
  { path: 'failure', loadChildren: () => import('./failure/failure.module').then(m => m.FailureModule)},
  { path: 'privacy-policy', loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)},
  { path: 'results', loadChildren: () => import('./result/result.module').then(m => m.ResultModule)},
  { path: 'combo-card-payment', loadChildren: () => import('./combo-card-paymnet/combo-card-paymnet.module').then(m => m.ComboCardPaymnetModule)},

  // { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),canActivate: [AuthSuperadminGuard]},

  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
