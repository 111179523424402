// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
export const environment = {
  appName: "CONVOCATION_SUPERADMIN",
  production: false,

    // apiUrl: 'https://api.revelsmit.in/',
   apiUrl: 'https://dev-api.revelsmit.in/',
  // apiUrl: 'http://devapi-convocation.manipal.edu/', //dev
  // apiUrl: 'https://staggingapi.manipalsuperadmin.com/',
  oneSignalAppId: 'c382baff-142f-4e16-b7c4-f6133d400ea4', //dev
  // media_domain: 'https://dzklmbj651gn4.cloudfront.net/development', //dev
  // media_domain: 'https://d163sfb531qyyt.cloudfront.net/production',
 media_domain: 'https://d163sfb531qyyt.cloudfront.net/revels/testing',
  apiPrefix: 'api/v1',
  secret:'mysecretkeysecretrevels'

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
