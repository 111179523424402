import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
      register(roll_no:any) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/register-mahe`, { enrollment_number: roll_no,user_type:"MAHE" }).pipe(map(user => {
            return user;
        }));
    }

    login(phone:any) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/login`, { mobile: phone }).pipe(map(user => {
            return user;
        }));
    }

    resendotp(phone:any) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/resend-otp`, { mobile_number: phone }).pipe(map(user => {
            return user;
        }));
    }


    nonmaheregister(name,email,phone,id_no,college_name) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/register-non-mahe`, { full_name: name,user_type:"NONMAHE",email:email,mobile:phone,
        enrollment_number:id_no,institution:college_name }).pipe(map(user => {
            return user;
        }));
    }

    verifyotp(no,otp:any) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/users/auth/verify-otp`, {otp: otp,mobile_number:no }).pipe(map(user => {
            // if(user.status.code==200){
                localStorage.setItem(`${environment.appName}` + '_user', user.data.token);
                localStorage.setItem(`${environment.appName}` + '_name', user.data.user.full_name);
                localStorage.setItem(`${environment.appName}` + '_course_id', user.data.user.course_id);
                localStorage.setItem(`${environment.appName}` + '_degree_id', user.data.user.degree_id);
                localStorage.setItem(`${environment.appName}` + 'delegate_id', user.data.user.delegate_id);
                localStorage.setItem(`${environment.appName}` + 'enrollment_number', user.data.user.enrollment_number);
                localStorage.setItem(`${environment.appName}` + 'full_name', user.data.user.full_name);
                localStorage.setItem(`${environment.appName}` + 'status', user.data.user.is_active);
                localStorage.setItem(`${environment.appName}` + 'user_type', user.data.user.user_type);
                return user;
            // }
        }));
    }

    logout() {
        localStorage.removeItem(`${environment.appName}` + '_user_obj');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_role');
    }

    public isAuth() {
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            return true;
        } else {
            return false;
        }
    }


}